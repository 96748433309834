import * as React from "react"
import Image from "./imageComponent";
import AniLink from "gatsby-plugin-transition-link/AniLink"


class Header extends React.Component {
    componentDidMount() {
        window.addEventListener("scroll", this.toggleHeaderClass);
        this.toggleHeaderClass();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.toggleHeaderClass);
    }

    toggleHeaderClass = () => {
        if (window.scrollY > 100) {
            document.getElementById("header").classList.add("blur");
        } else {
            document.getElementById("header").classList.remove("blur");
        }
    };

    toggleMenu = () => {
        if (document.getElementById("hamburger").classList.contains('is-active')) {
            document.getElementById("header").classList.remove("menu-opened");
            document.getElementById("hamburger").classList.remove("is-active");
        }else{
            document.getElementById("hamburger").classList.add("is-active");
            document.getElementById("header").classList.add("menu-opened");
        }
    }

    menuCompontent(submenu){
        return(
            <div className={'row align-items-center'}>
                {submenu.icon && <div className={'col-auto pe-1'}>
                    <div className={'icon'}>
                        <Image alt={submenu.text} filename={submenu.icon} />
                    </div>
                </div>}
                <div className={'col ps-2'}>
                    <div className={'title'}>{submenu.text}</div>
                </div>
            </div>
        )
    }


    render() {
              return (
            <header id={'header'}>
                <div className={'blurry'}/>
                <div className={'container'}>
                    <button id={'hamburger'} className="hamburger" type="button" onClick={this.toggleMenu}>
                      <div className={'inner'}><span/></div>
                    </button>
                    <div className={'logo'}>
                        <AniLink fade duration={0.1} hex="#000000" to="/"><img src={this.props.logo} alt="Penumbra"/></AniLink>
                    </div>
                    <nav>
                        <ul>
                            {this.props.navigation.map((link, index) => (
                                <li key={index}>
                                    {link.submenus ? <div className={'hasSubmenu'}>{link.text}</div> : link.type === 'external' ?
                                        <a href={`${link.url}`} target={'_blank'} rel={'noreferrer'}>{link.text}</a> : <AniLink fade duration={0.1} hex="#000000" to={`${link.url}`} activeClassName="active">{link.text} {link.submenus && <i className={'icon-dropdown'}/>}</AniLink>}
                                    {link.submenus &&
                                    <div className={'submenu'}>
                                        <ul>
                                            {link.submenus.map((submenu, index) => (
                                                <li key={index}>
                                                    {submenu.type === 'external' ? <a href={submenu.url} target="_blank" rel={'noreferrer'}>{this.menuCompontent(submenu)}</a> :
                                                    <AniLink fade duration={0.1} hex="#000000" to={submenu.url}>{this.menuCompontent(submenu)}</AniLink>
                                                    }

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    }

                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className={'clear'}/>
                </div>
            </header>
        );
    }
}

export default Header
