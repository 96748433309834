import React from "react"
import Header from "../modules/header";
import Footer from "../modules/footer";
import { Helmet } from "react-helmet";

import faktumbold from "../fonts/faktum-bold-webfont.woff2"
import faktumsemibold from "../fonts/faktum-semibold-webfont.woff2"
import faktummedium from "../fonts/faktum-medium-webfont.woff2"
import devanagari from "../fonts/DevanagariSangamMN.woff2"
import devanagariBold from "../fonts/DevanagariSangamMN-Bold.woff2"

import logo from '../images/penumbra-logo.svg'

export const technologies = [
    {
        text: "Transact",
        url: "/technology/transact",
        icon: ""
    },
    {
        text: "Stake",
        url: "/technology/stake",
        icon: ""
    },
    {
        text: "Swap",
        url: "/technology/swap",
        icon: ""
    },
    {
        text: "Marketmake",
        url: "/technology/marketmake",
        icon: ""
    }
];

const navigation = [
    {
        text: "Technology",
        url: "/technology",
        submenus: technologies,
        type: 'internal'
    }, {
        text: "Spec",
        url: "https://protocol.penumbra.zone",
        type: 'external'
    }, {
        text: "Blog",
        url: "/blog",
        type: 'internal'
    }, {
        text: "Community",
        url: "/community",
        type: 'internal',
        submenus: [
            {
                text: "Github",
                url: "http://github.com/penumbra-zone",
                icon: "menu/github.svg",
                type: 'external'
            },
            {
                text: "Discord",
                url: "https://discord.gg/hKvkrqa3zC",
                icon: "menu/discord.svg",
                type: 'external'
            },
            {
                text: "Twitter",
                url: "https://twitter.com/penumbrazone",
                icon: "menu/twitter.svg",
                type: 'external'
            }
        ]
    }, {
        text: "Get Started",
        url: "https://guide.penumbra.zone",
        type: 'external'
    }
]



export default function Layout({ children, title, className }) {
    return (
        <div className={className + '-page'}>
            <Helmet>
                <title>{`Penumbra - ${title}`}</title>
                <link rel="preload" href={faktumbold} as="font" type="font/woff2" crossOrigin />
                <link rel="preload" href={faktumsemibold} as="font" type="font/woff2" crossOrigin />
                <link rel="preload" href={faktummedium} as="font" type="font/woff2" crossOrigin />

                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap" rel="stylesheet" />

                <link rel="preload" as="image" href={logo} />

                <link rel="apple-touch-icon" sizes="57x57" href="../favicons/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../favicons/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../favicons/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../favicons/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../favicons/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../favicons/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../favicons/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../favicons/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../favicons/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../favicons/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../favicons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../favicons/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../favicons/favicon-16x16.png" />
                <link rel="manifest" href="../favicons/manifest.json" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                <meta name="theme-color" content="#000000" />
            </Helmet>

            <Header navigation={navigation} logo={logo} />
            <main>
                {children}
            </main>
            <Footer logo={logo} />
        </div>
    )
}
