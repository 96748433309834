import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import {technologies} from "../components/layout";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-auto pe-5'}>
                            <div className={'logo'}><AniLink fade duration={0.1} hex={'#000000'} to="/"><img src={this.props.logo} alt="Penumbra"/></AniLink></div>
                        </div>
                        <div className={'col-6 col-md-auto pe-5 ps-5'}>
                            <ul>
                                <li><AniLink fade duration={0.1} hex="#000000" to={'/#roadmap'}>Roadmap</AniLink></li>
                                <li><AniLink fade duration={0.1} hex="#000000" to={'/team'}>Team</AniLink></li>
                                <li><AniLink fade duration={0.1} hex="#000000" to={'/careers'}>Careers</AniLink></li>
                                <li><AniLink fade duration={0.1} hex="#000000" to={'/blog'}>Blog</AniLink></li>
                            </ul>
                        </div>
                        <div className={'col-6 col-md-auto pe-5 ps-5'}>
                            <ul>
                                {technologies.map((submenu, index) => (
                                    <li key={index}><AniLink fade duration={0.1} hex="#000000" to={submenu.url}>{submenu.text}</AniLink></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={'row justify-content-end copyright'}>
                        Created by <a href={'https://designatives.com'} target={'_blank'} rel={'noreferrer'}>Designatives</a>
                    </div>
                    <div className={'clear'}/>
                </div>
            </footer>
        );
    }
}

export default Footer
